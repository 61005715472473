/* Animation Keyframes */
/* @keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(5.05);
  }
} */

/* @keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
} */

/* Container styling
.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
  padding: 1rem;
} */

/* Image wrapper */
/* .image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 28rem;
  width: 100%;
  margin: 0 auto;
} */

/* SVG Image Styling and Animations */
/* .svg-image { */
  /* width: 15rem; */
  /* height: 15rem; */
  /* animation: pulse 3s infinite ease-in-out, float 5s infinite ease-in-out; Removed rotate animation */
/* } */

/* Responsive breakpoints */
/* @media (min-width: 768px) {
  .svg-image {
    width: 20rem;
    height: 20rem;
  }
}

@media (min-width: 1024px) {
  .svg-image {
    width: 25rem;
    height: 25rem;
  }
} */

/* General reset for box-sizing */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

/* App.css */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1e1e2f;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.image-wrapper {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(145deg, #29293e, #1b1b2b);
  box-shadow: 8px 8px 16px #141422, -8px -8px 16px #242436;
}

h1 {
  font-size: 3rem;
  color: #ff6b6b;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

p {
  font-size: 1.2rem;
  color: #b8b8d1;
}

a {
  color: #ffb86c;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #ff6b6b;
}
